@use "sass:map";

@import "custom-bs-import";

// Layout & components
// Only import what we need:
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
// @import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
// @import "bootstrap/scss/offcanvas";
// @import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

// Make the bootstrap icons available.
$bootstrap-icons-font-src: url("npm:bootstrap-icons/font/fonts/bootstrap-icons.woff2") format("woff2"),
url("npm:bootstrap-icons/font/fonts/bootstrap-icons.woff") format("woff");
@import "bootstrap-icons/font/bootstrap-icons";

// Leave room for fixed-top navbar...
body.navbar-offset {
    padding-top: 60px;
}

// ...and make sure it doesn't overlap when we scroll to anchors.
html {
    scroll-padding-top: 60px;
}

// Toggle classes for dark/light modes
[data-bs-theme="dark"] {
    .d-dm-none {
        display: none;
    }

    .d-lm-none {
        display: initial;
    }
}

[data-bs-theme="light"] {
    .d-dm-none {
        display: initial;
    }

    .d-lm-none {
        display: none;
    }
}

// Make submenus open on hover.
@include media-breakpoint-up(lg) {
    .dropdown-menu>li>ul {
        display: none;
    }

    .dropdown-menu>li:hover>ul {
        display: block;
    }

}

@include media-breakpoint-up(md) {
    .leftmenu .nav>li>ul {
        display: none;
    }

    .leftmenu .nav>li:hover>ul {
        display: block;
    }
}

:is(.dropdown-menu, .leftmenu .nav) .dropdown-menu {
    top: 0;
    left: 100%;
    right: auto;
}

// Make textareas in forms use a monospace font
textarea.form-control {
    font-family: $font-family-code;
    font-size: $code-font-size;
}

// Wrap long lines in preformatted text.
pre {
    white-space: pre-wrap;
    margin-bottom: 0;
}

// Size the navbar brand image.
.navbar-brand img {
    height: auto;
    width: 60px;
}

// Style preformatted alert messages better.
.preformatted {
    white-space: pre-line;
}

.leftmenu {
    width: 13em;

    li>ul {
        font-size: inherit; // Make the font size choice cascade down into group menu dropdowns.
    }

    .dropdown-item {
        // TODO: In bs-5.2.0, components began to be styled with CSS variables.
        // This broke the (ab)use of dropdown-item to style parts of the leftmenu.
        // This is a quick fix, but we should think about redoing the leftmenu properly.
        --#{$prefix}dropdown-min-width: #{$dropdown-min-width};
        --#{$prefix}dropdown-padding-x: #{$dropdown-padding-x};
        --#{$prefix}dropdown-padding-y: #{$dropdown-padding-y};
        --#{$prefix}dropdown-spacer: #{$dropdown-spacer};
        @include rfs($dropdown-font-size, --#{$prefix}dropdown-font-size);
        --#{$prefix}dropdown-color: #{$dropdown-color};
        --#{$prefix}dropdown-bg: #{$dropdown-bg};
        --#{$prefix}dropdown-border-color: #{$dropdown-border-color};
        --#{$prefix}dropdown-border-radius: #{$dropdown-border-radius};
        --#{$prefix}dropdown-border-width: #{$dropdown-border-width};
        --#{$prefix}dropdown-inner-border-radius: #{$dropdown-inner-border-radius};
        --#{$prefix}dropdown-divider-bg: #{$dropdown-divider-bg};
        --#{$prefix}dropdown-divider-margin-y: #{$dropdown-divider-margin-y};
        --#{$prefix}dropdown-box-shadow: #{$dropdown-box-shadow};
        --#{$prefix}dropdown-link-color: #{$nav-link-color};
        --#{$prefix}dropdown-link-hover-color: #{$nav-link-hover-color};
        --#{$prefix}dropdown-link-hover-bg: #{$dropdown-link-hover-bg};
        --#{$prefix}dropdown-link-active-color: #{$dropdown-link-active-color};
        --#{$prefix}dropdown-link-active-bg: #{$dropdown-link-active-bg};
        --#{$prefix}dropdown-link-disabled-color: #{$dropdown-link-disabled-color};
        --#{$prefix}dropdown-item-padding-x: #{$dropdown-item-padding-x};
        --#{$prefix}dropdown-item-padding-y: #{$dropdown-item-padding-y};
        --#{$prefix}dropdown-header-color: #{$dropdown-header-color};
        --#{$prefix}dropdown-header-padding-x: #{$dropdown-header-padding-x};
        --#{$prefix}dropdown-header-padding-y: #{$dropdown-header-padding-y};
    }
}

// Use fixed lengths for the first three columns of "meta" tables.
table tbody.meta {

    th:first-child,
    td:first-child {
        width: 4em;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 7em;
    }

    // See https://getbootstrap.com/docs/5.1/layout/breakpoints/#media-queries
    @include media-breakpoint-up(md) {

        th:first-child,
        td:first-child {
            width: 9em;
        }

        th:nth-child(2),
        td:nth-child(2) {
            width: 14em;
        }
    }

    td.edit {
        width: 1px; // Make sure it uses as little space as possible
    }
}

// Try and hyphenate table headings and other things
th,
.hyphenate {
    hyphens: auto;
}

// Helper to make sure very wide tables work on narrow screens.
.wrap-anywhere {
    overflow-wrap: anywhere;
}

// Highlight required form field labels with bi-asterisk
.required>label:after {
    display: inline-block;
    width: .75rem;
    height: .75rem;
    margin-left: .125rem;
    margin-right: .125rem;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    // Recolor black to $red, see https://codepen.io/sosuke/pen/Pjoqqp
    filter: invert(35%) sepia(9%) saturate(5669%) hue-rotate(312deg) brightness(102%) contrast(117%);
    // font-weight: bold;
}

// Make the long dropdowns in the group menu scrollable.
.group-menu .dropdown-menu {
    height: auto;
    width: auto;
    max-height: 35em;
    overflow-x: hidden;
    overflow-y: auto;
}

// Helper to constrain the size of the main logo
.ietflogo {
    width: 100%;
    max-width: 300px;
}
.ietflogo > img {
    min-width: 100px;
    width: 100%;
}

// Make revision numbers pagination items fixed-width
.revision-list {
    .page-item {
        width: 2.2rem;
    }

    .page-item.rfc {
        width: 6.6rem;
    }
}

.charter.revision-list {
    .page-item {
        width: auto;
    }
}

// Style the photo cards
.photo {
    width: 12em;

    .card-img-top {
        object-fit: cover;
        height: 15em;
    }

    .photo-placeholder {
        height: 15em;

        .bi {
            font-size: 10em;
            color: $gray-300;
        }
    }
}

// Style the righthand navigation panel
#righthand-panel {
    max-height: 80vh;
}

#righthand-nav {
    height: 70vh;
    width: inherit;
    overscroll-behavior-y: none; // Prevent overscrolling from scrolling the main content
}

// Add some padding when there are multiple buttons in a line than can wrap
.buttonlist .btn {
    margin-bottom: map.get($spacers, 1);
}

// Styles for d3.js graphical SVG timelines
#doc-timeline {
    font-size: small;

    .axis path,
    .axis line {
        fill: none;
        stroke: var(--bs-body-color);
    }

    .axis.y path,
    .axis.y line {
        stroke: none;
    }

    .axis text {
        font-size: small;
    }

    .axis.x text {
        dominant-baseline: central;
    }

    .bar text {
        fill: var(--bs-body-color);
        dominant-baseline: central;
        pointer-events: none;
    }

    $timeline-odd-color: $info;
    $timeline-odd-hover-color: shift-color($timeline-odd-color, $link-shade-percentage) !default;

    .bar:nth-child(odd) rect {
        fill: $timeline-odd-color;
    }

    .bar:nth-child(odd) rect:hover,
    .bar:nth-child(odd) rect:focus {
        fill: $timeline-odd-hover-color;
    }

    $timeline-even-color: $warning;
    $timeline-even-hover-color: shift-color($timeline-even-color, $link-shade-percentage) !default;

    .bar:nth-child(even) rect {
        fill: $timeline-even-color;
    }

    .bar:nth-child(even) rect:hover,
    .bar:nth-child(even) rect:focus {
        fill: $timeline-even-hover-color;
    }
}

// Styles needed for the ballot table

.ballot-icon table td {
    border: $table-border-width solid $gray-500;
    height: 1em;
    width: .8em;
}

.ballot-icon table .my {
    border: calc(2 * $table-border-width) solid var(--bs-emphasis-color);
}

// See https://getbootstrap.com/docs/5.1/customize/color/#all-colors
$color-discuss: $red-500;
$color-yes: $green-500;
$color-noobj: $green-100;
$color-abstain: $orange-400;
$color-recuse: $purple-400;
$color-norecord: $gray-300;

.is-blocking {
    box-shadow: 0 0 map.get($spacers, 2) map.get($spacers, 2) $color-discuss;
    margin: map.get($spacers, 2);
}

.color-discuss {
    color: $color-discuss;
}

.color-yes {
    color: $color-yes;
}

.color-noobj {
    color: $color-noobj;
}

.color-abstain {
    color: $color-abstain;
}

.color-recuse {
    color: $color-recuse;
}

.color-norecord {
    color: $color-norecord;
}

.bg-discuss {
    background-color: $color-discuss;
}

.bg-yes {
    background-color: $color-yes;
}

.bg-noobj {
    background-color: $color-noobj;
}

.bg-abstain {
    background-color: $color-abstain;
}

.bg-recuse {
    background-color: $color-recuse;
}

.bg-norecord {
    background-color: $color-norecord;
}

.border-discuss {
    border-color: $color-discuss;
}

.border-yes {
    border-color: $color-yes;
}

.border-noobj {
    border-color: $color-noobj;
}

.border-abstain {
    border-color: $color-abstain;
}

.border-recuse {
    border-color: $color-recuse;
}

.border-norecord {
    border-color: $color-norecord;
}

td.position-moretime,
td.position-notready,
td.position-discuss,
td.position-block {
    background-color: $color-discuss;
}

td.position-yes {
    background-color: $color-yes;
}

td.position-noobj {
    background-color: $color-noobj;
}

td.position-abstain {
    background-color: $color-abstain;
}

td.position-recuse {
    background-color: $color-recuse;
}

td.position-norecord {
    background-color: transparent;
}

td.position-empty {
    border: none !important;
}

[data-bs-theme="light"] {

    tr.position-moretime-row,
    tr.position-notready-row,
    tr.position-discuss-row,
    tr.position-block-row {
        background-color: tint-color($color-discuss, 85%);
    }

    tr.position-yes-row {
        background-color: tint-color($color-yes, 75%);
    }

    tr.position-noobj-row {
        background-color: tint-color($color-noobj, 50%);
    }

    tr.position-abstain-row {
        background-color: tint-color($color-abstain, 85%);
    }

    tr.position-recuse-row {
        background-color: tint-color($color-recuse, 85%);
    }
}

[data-bs-theme="dark"] {

    tr.position-moretime-row,
    tr.position-notready-row,
    tr.position-discuss-row,
    tr.position-block-row {
        background-color: shade-color($color-discuss, 85%);
    }

    tr.position-yes-row {
        background-color: shade-color($color-yes, 75%);
    }

    tr.position-noobj-row {
        background-color: shade-color($color-noobj, 75%);
    }

    tr.position-abstain-row {
        background-color: shade-color($color-abstain, 85%);
    }

    tr.position-recuse-row {
        background-color: shade-color($color-recuse, 85%);
    }
}

/* === Edit Meeting Schedule ====================================== */

.edit-meeting-schedule .edit-grid {
    position: relative;
    display: flex;
}

.edit-meeting-schedule .edit-grid .room-label-column {
    /* make sure we cut this column off - the timeslots will determine
   how much of it is shown */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 8em;
}

.edit-meeting-schedule .edit-grid .day {
    margin-left: 1em;
    margin-bottom: 2em;
}

.edit-meeting-schedule .edit-grid .room-label-column .day {
    margin-left: 0;
}

.edit-meeting-schedule .edit-grid .day-label {
    height: 3em;
}

.edit-meeting-schedule .edit-grid .day-label .swap-days {
    cursor: pointer;
}

.edit-meeting-schedule .edit-grid .day-label .swap-days:hover {
    color: var(--bs-secondary-color);
}

.edit-meeting-schedule #swap-days-modal .modal-body label {
    display: block;
}

.edit-meeting-schedule .edit-grid .day-flow {
    margin-left: 8em;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.edit-meeting-schedule .edit-grid .room-group:not(:last-child) {
    margin-bottom: 1em;
}

.edit-meeting-schedule .edit-grid .time-header {
    position: relative;
    height: 1.5em;
    padding-bottom: 0.15em;
}

.edit-meeting-schedule .edit-grid .time-header .time-label {
    display: inline-block;
    position: relative;
    width: 100%;
    align-items: center;
}

.edit-meeting-schedule .edit-grid .time-header .time-label.would-violate-hint {
    background-color: var(--bs-danger-bg-subtle);
    outline: var(--bs-danger-bg-subtle) solid 0.4em;
}

.edit-meeting-schedule .edit-grid .time-header .time-label span {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: var(--bs-secondary-color);
}

.edit-meeting-schedule .edit-grid .timeslots {
    position: relative;
    height: 4.5em;
    padding-bottom: 0.15em;
}

.edit-meeting-schedule .edit-grid .timeslot {
    position: relative;
    display: inline-block;
    background-color: var(--bs-secondary-bg);
    height: 100%;
    overflow: hidden;
}

.edit-meeting-schedule .edit-grid .timeslot .time-label {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: var(--bs-tertiary-color);
}

.edit-meeting-schedule .edit-grid .timeslot .drop-target {
    position: relative;
    /* this is merely to make sure we are positioned above the time labels */
    display: flex;
    flex-direction: row;
    height: 100%;
}

.edit-meeting-schedule .edit-grid .timeslot.dropping {
    background-color: #ccc;
    transition: background-color 0.2s;
}

.edit-meeting-schedule .edit-grid .timeslot.overfull {
    border-right: 0.3em dashed var(--bs-danger);
    /* cut-off illusion */
}

.edit-meeting-schedule .edit-grid .timeslot.would-violate-hint {
    background-color: var(--bs-danger-bg-subtle);
    outline: var(--bs-danger-bg-subtle) solid 0.4em;
}

.edit-meeting-schedule .edit-grid .timeslot.would-violate-hint.dropping {
    background-color: var(--bs-danger);
}

.edit-meeting-schedule .constraints .encircled,
.edit-meeting-schedule .formatted-constraints .encircled {
    border: 1px solid var( --bs-body-color);
    border-radius: 1em;
    padding: 0 0.3em;
    text-align: center;
    display: inline-block;
}

.edit-meeting-schedule .formatted-constraints .encircled {
    font-size: smaller;
}

/* sessions */
.edit-meeting-schedule .session {
    background-color: var(--bs-body-bg);
    margin: 0.2em;
    padding-right: 0.2em;
    padding-left: 0.5em;
    line-height: 1.3em;
    border-radius: 0.4em;
    overflow: hidden;
    cursor: pointer;
}

.edit-meeting-schedule .session.selected {
    cursor: grabbing;
    outline: var(--bs-primary) solid 0.2em;
    /* width matches margin on .session */
    z-index: 2;
    /* render above timeslot outlines */
}

.edit-meeting-schedule .session.other-session-selected {
    outline: var(--bs-info) solid 0.2em;
    /* width matches margin on .session */
    z-index: 2;
    /* render above timeslot outlines */
}

.edit-meeting-schedule .read-only .session.selected {
    cursor: default;
}

.edit-meeting-schedule .session.readonly {
    cursor: default;
    background-color: var(--bs-dark-bg-subtle);
}

.edit-meeting-schedule .session.hidden-parent * {
    /* This makes .session.hidden-parent's children transparent but keeps the
   * .session itself opaque so the timeslot label does not show through. */
    opacity: 0.7;
}

.edit-meeting-schedule .session.selected .session-label {
    font-weight: bold;
}

.edit-meeting-schedule .session.highlight {
    outline-color: var(--bs-warning);
    background-color: var(--bs-light);
}

.edit-meeting-schedule .session.would-violate-hint {
    outline: 0.3em solid var(--bs-danger);
    z-index: 1;
    /* raise up so the outline is not overdrawn */
}

.edit-meeting-schedule .session.highlight .session-label {
    font-weight: bold;
}

.edit-meeting-schedule .session.dragging {
    opacity: 0.1;
    transition: opacity 0.4s;
}

.edit-meeting-schedule .timeslot.overfull .session {
    border-radius: 0.4em 0 0 0.4em;
    /* remove right-side rounding to allude to being cut off */
    margin-right: 0;
}

.edit-meeting-schedule .edit-grid,
.edit-meeting-schedule .session {
    // Removing this font-family style causes selenium tests to fail :-(
    font-family: arial, helvetica, sans-serif;
    font-size: 11px;
}

.edit-meeting-schedule .session .session-label {
    flex-grow: 1;
    margin-left: 0.1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.edit-meeting-schedule .session .session-label .bof-tag {
    font-style: normal;
    font-size: smaller;
    color: #8b0000;
    font-weight: bold;
    float: right;
    margin-right: 0.2em;
}

.edit-meeting-schedule .session.too-many-attendees .attendees {
    font-weight: bold;
    color: #8432d4;
}

.edit-meeting-schedule .session .constraints {
    margin-right: 0.2em;
    text-align: right;
    flex-shrink: 1;
}

.edit-meeting-schedule .session .constraints>span {
    display: none;
    font-size: smaller;
}

.edit-meeting-schedule .session .constraints>span .encircled {
    border: 1px solid #b35eff;
}

.edit-meeting-schedule .session .constraints>span.violated-hint {
    display: inline-block;
    color: #8432d4;
}

.edit-meeting-schedule .session .constraints>span.would-violate-hint {
    display: inline-block;
    font-weight: bold;
    color: #f55;
}

.edit-meeting-schedule .session .constraints>span.would-violate-hint .encircled {
    border: 1px solid #f99;
}

.edit-meeting-schedule .unassigned-sessions .session .constraints>span {
    display: none;
}

.edit-meeting-schedule .session .session-info {
    display: none;
}

/* scheduling panel */
.edit-meeting-schedule .scheduling-panel {
    position: sticky;
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 0.2em solid var(--bs-border-color);
    margin-bottom: 2em;
    background-color: var(--bs-body-bg);
    opacity: 0.95;
    z-index: 5;
    /* raise above edit-grid items */
}

.edit-meeting-schedule .scheduling-panel .unassigned-container {
    flex-grow: 1;
}

.edit-meeting-schedule .unassigned-sessions {
    margin-top: 0.5em;
    min-height: 4em;
    max-height: 13em;
    overflow-y: auto;
    background-color: var(--bs-secondary-bg);
}

.edit-meeting-schedule .unassigned-sessions.dropping {
    background-color: #e5e5e5;
    transition: background-color 0.2s;
}

.edit-meeting-schedule .unassigned-sessions .drop-target {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: 5em;
    /* do not disappear when empty */
}

.edit-meeting-schedule .scheduling-panel .preferences {
    margin: 0.5em 0;
}

.edit-meeting-schedule .scheduling-panel .preferences>span {
    margin-top: 0;
    margin-right: 1em;
}

.edit-meeting-schedule .sort-unassigned select {
    width: auto;
    display: inline-block;
}

.edit-meeting-schedule #timeslot-group-toggles-modal .modal-body>div {
    margin-bottom: 1.5em;
}

.edit-meeting-schedule #timeslot-group-toggles-modal .modal-body .individual-timeslots {
    /*column-count: 3;*/
    display: flex;
    flex-flow: row wrap;
}

.edit-meeting-schedule #timeslot-group-toggles-modal .modal-body .individual-timeslots>* {
    margin-right: 1.5em;
}

.edit-meeting-schedule #timeslot-group-toggles-modal .modal-body .individual-timeslots label {
    display: block;
    font-weight: normal;
}

.edit-meeting-schedule .session-parent-toggles {
    margin-top: 1em;
}

.edit-meeting-schedule .toggle-inputs label {
    font-weight: normal;
    margin-right: 1em;
    padding: 0 1em;
    border: 0.1em solid var(--bs-border-color);
    cursor: pointer;
}

.edit-meeting-schedule .modal .day-options {
    display: flex;
    flex-flow: row wrap;
}

.edit-meeting-schedule .modal .timeslot-options {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
}

.edit-meeting-schedule .modal .room-group {
    margin: 2em;
}

.edit-meeting-schedule .scheduling-panel .session-info-container {
    padding-left: 0.5em;
    flex: 0 0 25em;
    height: 20em;
    font-size: 14px;
    overflow-y: auto;
}

.edit-meeting-schedule .scheduling-panel .session-info-container .comments {
    font-style: italic;
}

.edit-meeting-schedule .scheduling-panel .session-info-container .other-session:hover {
    cursor: default;
    background-color: #eee;
}

/* A modified .container-fluid without padding on very narrow devices*/
.container-fluid-narrow {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (max-width: 480px) {
    .container-fluid-narrow {
        padding-right: 0;
        padding-left: 0;
        margin-right: auto;
        margin-left: auto;
    }
}

/* === Edit Meeting Timeslots and Misc Sessions =================== */

.edit-meeting-timeslots-and-misc-sessions .day {
    margin-bottom: 1em;
}

.edit-meeting-timeslots-and-misc-sessions .day-label {
    text-align: center;
    font-size: 20px;
    margin-bottom: 0.4em;
}

.edit-meeting-timeslots-and-misc-sessions .room-row {
    border-bottom: 1px solid var(--bs-border-color);
    // height: 20px;
    display: flex;
    cursor: pointer;
}

.edit-meeting-timeslots-and-misc-sessions .room-label {
    width: 12em;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.edit-meeting-timeslots-and-misc-sessions .timeline {
    position: relative;
    flex-grow: 1;
}

.edit-meeting-timeslots-and-misc-sessions .timeline.hover {
    background: radial-gradient(var(--bs-tertiary-color) 1px, transparent 1px);
    background-size: 20px 20px;
}

.edit-meeting-timeslots-and-misc-sessions .timeline.selected.hover,
.edit-meeting-timeslots-and-misc-sessions .timeline.selected {
    background: radial-gradient(var(--bs-tertiary-color) 2px, transparent 2px);
    background-size: 20px 20px;
}

.edit-meeting-timeslots-and-misc-sessions .timeslot {
    position: absolute;
    overflow: hidden;
    background-color: #f0f0f0;
    opacity: 0.8;
    // height: 19px;
    top: 0px;
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    padding-left: 0.2em;
    border-left: 1px solid var(--bs-border-color);
    border-right: 1px solid var(--bs-border-color);
}

.edit-meeting-timeslots-and-misc-sessions .timeslot:hover {
    background-color: #ccc;
}

.edit-meeting-timeslots-and-misc-sessions .timeslot.selected {
    background-color: #bbb;
}

.edit-meeting-timeslots-and-misc-sessions .timeslot .session.cancelled {
    color: #a00;
}

.edit-meeting-timeslots-and-misc-sessions .scheduling-panel {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 0.2em solid var(--bs-border-color);
    padding-top: 0.2em;
    margin-bottom: 2em;
    background-color: var(--bs-body-bg);
    opacity: 0.95;
}

.edit-meeting-timeslots-and-misc-sessions .scheduling-panel form {
    display: flex;
    align-items: flex-start;
}

.edit-meeting-timeslots-and-misc-sessions .scheduling-panel form button {
    margin: 0 0.5em;
}

.edit-meeting-timeslots-and-misc-sessions .scheduling-panel .flowing-form {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.edit-meeting-timeslots-and-misc-sessions .scheduling-panel .flowing-form .mb-3 {
    margin-right: 1em;
    margin-bottom: 0.5em;
}

.edit-meeting-timeslots-and-misc-sessions .scheduling-panel .flowing-form label {
    display: inline-block;
    margin-right: 0.5em;
}

.edit-meeting-timeslots-and-misc-sessions .scheduling-panel .flowing-form .form-control {
    display: inline-block;
    width: auto;
}

.edit-meeting-timeslots-and-misc-sessions .scheduling-panel .flowing-form [name=time],
.edit-meeting-timeslots-and-misc-sessions .scheduling-panel .flowing-form [name=duration] {
    width: 6em;
}

.edit-meeting-timeslots-and-misc-sessions .scheduling-panel .flowing-form [name=name] {
    width: 25em;
}

.edit-meeting-timeslots-and-misc-sessions .scheduling-panel .flowing-form [name=short] {
    width: 10em;
}

.timeslot-edit .tstable div.timeslot {
    border: var(--bs-body-color) solid 1px;
    border-radius: 0.5em;
    padding: 0.5em;
}

.timeslot-edit .tstable .timeslot .ts-name {
    overflow: hidden;
}

.timeslot-edit .tstable .timeslot .ts-type {
    font-size: smaller;
}

.timeslot-edit .tstable .timeslot .timeslot-buttons {
    float: right;
}

.timeslot-edit .tstable .timeslot.in-official-use {
    background-color: #d9edf7;
}

.timeslot-edit .tstable .timeslot.in-unofficial-use {
    background-color: #f8f8e0;
}

.timeslot-edit .tstable td.timeslot-collision {
    background-color: #ffa0a0;
}

.timeslot-edit .tstable .tstype_unavail {
    background-color: var(--bs-secondary-color);
}

.timeslot-edit .official-use-warning {
    color: #ff0000;
}

.rightmarker,
.leftmarker {
    width: 3px;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

#agenda-table tbody tr.current-session {
    border-top: map.get($border-widths, 2) solid map.get($theme-colors, "info");
}

.timetooltip {
    position: relative;
}

.timetooltip .timetooltiptext {
    visibility: hidden;
    background-color: #eee;
    color: #000;
    text-align: left;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 110;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    width: 60em;
}

.reschedtimetooltip .timetooltiptext {
    margin-left: -300px;
}

.timetooltiptext table tr td {
    padding: 1px 5px;
}

.timetooltiptext table tr th {
    text-align: center;
}

.timehead {
    text-align: right;
    font-weight: bold;
}

.timetooltip:hover .timetooltiptext {
    visibility: visible;
    opacity: 1;
}

#current-time {
    display: inline-block;
}

.meeting-switch {
    background-color: lighten($gray-600, 15%);
    padding: 5px 10px;
    text-align: center;
    color: #FFF;
    font-size: .9rem;
    font-weight: 500;

    a {
        color: #FFF;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .4);
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

blockquote {
    padding-left: 1rem;
    border-left: solid 1px var(--bs-body-color);
}

iframe.status {
    background-color:transparent;
    border:none;
    width:100%;
    height:3.5em;
}

.overflow-shadows {
    transition: box-shadow 0.5s;
}

.overflow-shadows--both {
    box-shadow: inset 0px 21px 18px -20px var(--bs-body-color),
                inset 0px -21px 18px -20px var(--bs-body-color);
}

.overflow-shadows--top-only {
    box-shadow: inset 0px 21px 18px -20px var(--bs-body-color);
}

.overflow-shadows--bottom-only {
    box-shadow: inset 0px -21px 18px -20px var(--bs-body-color);
}
